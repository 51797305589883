
<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card loader-height="2" class="mb-3">
          <v-card-text>
            <div class="d-flex align-center">
              <v-btn
                rounded
                color="primary"
                @click="
                  addProjectDialog = true;
                  iaProject = { status: true };
                "
                elevation="0"
                small
                class="mr-2"
                ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
              >
              <lb-string label="Search" outlined hidedetails></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
              <v-btn icon small class="ml-2"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <v-row>
          <template>
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="(v, k) in ProjectList"
              :key="k"
            >
              <v-card
                min-height="200"
                link
                @click="
                  $nova.gotoLink({
                    path: '/ia/view/' + v._id,
                  })
                "
                class="shadow-off"
              >
                <v-card-text>
                  <div class="d-flex align-center">
                    <div class="font-weight-bold">{{ v.name }}</div>
                    <v-chip
                      color="info"
                      v-if="v.stage === 0"
                      small
                      label
                      class="body-2 ml-2"
                    >
                      Open
                    </v-chip>
                    <v-chip
                      color="warning"
                      small
                      v-if="v.stage === 1"
                      label
                      class="body-2 ml-2"
                    >
                      Review
                    </v-chip>
                    <v-chip
                      color="success"
                      small
                      v-if="v.stage === 2"
                      label
                      class="body-2 ml-2"
                    >
                      Close
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-btn fab x-small @click.stop="editProject(v)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                  <div class="">
                    <div class="d-flex align-center grey--text text--darken-2 mb-3">
                      <span class="mr-2"
                        ><v-icon small class="mr-1">mdi-calendar-range</v-icon>:
                      </span>
                      
                      <span class="mr-1">{{ $nova.formatDate(v.from) }}</span> 
                      <span class="font-weight-bold">to</span> 
                      <span class="mr-1 ml-1">{{ $nova.formatDate(v.to) }}</span>
                      <v-spacer></v-spacer>
                    </div>
                    <div class="d-flex">
                       <span class="pr-2 font-weight-bold grey--text text--darken-2">User List:</span>
                      <v-spacer></v-spacer>
                      <span class="text-medium-emphasis"
                        >Count:<span class="font-weight-black">{{
                          v.userdetails.length
                        }}</span></span
                      >
                    </div>
                    <div class="mt-2">
                      <v-chip
                        v-for="(colum, i) in v.userdetails"
                        :key="'B' + i"
                        class="ma-1"
                        label
                        text-color="black"
                        color="blue-grey lighten-5"
                        small
                        dense
                      >
                        {{ colum.name }}
                      </v-chip>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </div>
    </div>

    <v-dialog v-model="addProjectDialog" max-width="600" persistent>
      <v-card loader-height="2" class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="iaProject._id"
            >Update Project</span
          >
          <span class="subtitle-1 white--text" v-else>Add Project</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="addProjectDialog = false"
            class="ml-2 white--text"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string v-model="iaProject.name" label="Name" />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                v-model="iaProject.areas"
                label="Areas"
                :items="areaList"
                multiple
                itemtext="name"
                itemvalue="_id"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                v-model="iaProject.users"
                dense
                label="User List"
                :items="usersList"
                multiple
                itemtext="name"
                itemvalue="_id"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-date
                v-model="iaProject.from"
                label="Start"
                :min="$nova.formatDateISO(new Date())"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <lb-date
                v-model="iaProject.to"
                label="End"
                :min="$nova.formatDateISO(new Date())"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            v-if="iaProject._id"
            @click="updateProject"
            >Update</v-btn
          >
          <v-btn small color="primary" v-else @click="addProject">Add</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addProjectDialog: false,
      iaProject: { status: true },
      usersList: [],
      ProjectList: [],
      areaList: [],
    };
  },
  created() {
    this.getUserList();
    this.getAreaList();
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/iaprojects/get")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.ProjectList = dt.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUserList() {
      this.axios
        .post("/v2/conserve/iaprojects/userlist")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.usersList = dt.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAreaList() {
      this.axios
        .post("/v2/conserve/area/list")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.areaList = dt.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addProject() {
      console.log(this.iaProject);
      this.axios
        .post("/v2/conserve/iaprojects/add", { data: this.iaProject })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.iaProject = {};
            this.addProjectDialog = false;
            this.refreshData();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateProject() {
      this.axios
        .post("/v2/conserve/iaprojects/edit/" + this.iaProject._id, {
          data: this.iaProject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.iaProject = {};
            this.addProjectDialog = false;
            this.refreshData();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeUser(item) {
      const index = this.iaProject.users.indexOf(item);
      console.log(index);
      if (index >= 0) this.iaProject.users.splice(index, 1);
    },
    editProject(item) {
      this.addProjectDialog = true;
      this.iaProject = item;
    },
  },
};
</script>